import './landing_3.scss'
export default function Landing_3() {
  return (
    <div className='landing_3'>
      <div className='image_wrapper flex flex-col items-center justify-center'>
        <div className='mx-auto'>
          <div className='w-full relative'>
            <img src='/landing-page.png' />
            <button
              className='absolute top-[624px] h-[76px] w-[470px] left-[115px]'
              onClick={() => {
                window.open('https://t.me/sinoent', '_blank')
              }}
            ></button>
            <button
              className='absolute top-[1221px] h-[76px] w-[470px] left-[115px]'
              onClick={() => {
                window.open('https://t.me/+xh67F9WxyTIyZGM9', '_blank')
              }}
            ></button>
            <div className='absolute flex top-[900px] px-[30px] gap-[21px] '>
              <button
                className='w-[199px] h-[226px]'
                onClick={() => {
                  window.open('https://www.youtube.com/@psychopenguin1', '_blank')
                }}
              ></button>
              <button
                className='w-[199px] h-[226px]'
                onClick={() => {
                  window.open('https://www.youtube.com/@sinoentppshorts', '_blank')
                }}
              ></button>
              <button
                className='w-[199px] h-[226px]'
                onClick={() => {
                  window.open('https://www.youtube.com/@sinoentppmt', '_blank')
                }}
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
